import $ from '~/js/lib/jquery';
import * as _ from '~/js/lib/underscore';
import NProgress from '~/js/components/nprogress';

window.$ = window.jQuery = $;
window._ = _;
window.NProgress = NProgress;

document.addEventListener('DOMContentLoaded', async () => {
  if (document.querySelector('sk-site-notifications')) {
    await import('~/js/components/site_notifications');
  }

  if (document.querySelector('sk-browse-autocomplete')) {
    await import('~/js/components/autocomplete/browse');
  }
});
