import mixin from 'underscore/modules/mixin';
import each from 'underscore/modules/each';
import escape from 'underscore/modules/escape';
import range from 'underscore/modules/range';

// With modular imports, _ is empty. Certain functions require _ to have methods.
// For example, _.template depends on _.escape. So we have to extend _.
mixin({ each, escape, range });

/**
 * Moving underscore.js here and import only the functions we need.
 * We have many dependencies relying on underscore, but eventually we should
 * be able to delete these imports one by one.
 */
export { default as bind } from 'underscore/modules/bind';
export { default as clone } from 'underscore/modules/clone';
export { default as compact } from 'underscore/modules/compact';
export { default as create } from 'underscore/modules/create';
export { default as debounce } from 'underscore/modules/debounce';
export { default as defaults } from 'underscore/modules/defaults';
export { default as each } from 'underscore/modules/each';
export { default as escape } from 'underscore/modules/escape';
export { default as extend } from 'underscore/modules/extend';
export { default as filter } from 'underscore/modules/filter';
export { default as find } from 'underscore/modules/find';
export { default as findIndex } from 'underscore/modules/findIndex';
export { default as first } from 'underscore/modules/first';
export { default as flatten } from 'underscore/modules/flatten';
export { default as has } from 'underscore/modules/has';
export { default as indexOf } from 'underscore/modules/indexOf';
export { default as invoke } from 'underscore/modules/invoke';
export { default as isArray } from 'underscore/modules/isArray';
export { default as isBoolean } from 'underscore/modules/isBoolean';
export { default as isEqual } from 'underscore/modules/isEqual';
export { default as isFunction } from 'underscore/modules/isFunction';
export { default as isNumber } from 'underscore/modules/isNumber';
export { default as isObject } from 'underscore/modules/isObject';
export { default as isRegExp } from 'underscore/modules/isRegExp';
export { default as isString } from 'underscore/modules/isString';
export { default as keys } from 'underscore/modules/keys';
export { default as map } from 'underscore/modules/map';
export { default as matches } from 'underscore/modules/matcher';
export { default as object } from 'underscore/modules/object';
export { default as omit } from 'underscore/modules/omit';
export { default as once } from 'underscore/modules/once';
export { default as pick } from 'underscore/modules/pick';
export { default as pluck } from 'underscore/modules/pluck';
export { default as range } from 'underscore/modules/range';
export { default as reject } from 'underscore/modules/reject';
export { default as result } from 'underscore/modules/result';
export { default as some } from 'underscore/modules/some';
export { default as sortBy } from 'underscore/modules/sortBy';
export { default as template } from 'underscore/modules/template';
export { default as throttle } from 'underscore/modules/throttle';
export { default as toArray } from 'underscore/modules/toArray';
export { default as uniq } from 'underscore/modules/uniq';
export { default as uniqueId } from 'underscore/modules/uniqueId';
export { default as values } from 'underscore/modules/values';
export { default as wrap } from 'underscore/modules/wrap';
