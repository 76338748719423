export default {
  start() {
    if (this.$bar) return;

    this.$bar = document.createElement('div');

    Object.assign(this.$bar.style, {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '2px',
      backgroundColor: '#29d',
      zIndex: 9999,
      transform: 'translate3d(-100%, 0, 0)',
      transition: 'transform 20s ease-out',
      willChange: 'transform',
    });

    document.body.appendChild(this.$bar);
    requestAnimationFrame(() => {
      this.$bar.style.transform = 'translate3d(-10%, 0, 0)';
    });
  },
  done() {
    if (!this.$bar) return;

    Object.assign(this.$bar.style, {
      transform: 'translate3d(0, 0, 0)',
      transition: 'transform 0.3s ease-out',
    });

    setTimeout(() => {
      this.$bar.remove();
      this.$bar = null;
    }, 300);
  }
};
